.external-login-logo {
  width: 18px;
  height: 18px
}

.incust-logo {
  width: 24px;
  height: 24px;
  margin-left: -1.5px;
}

.google-logo {
  background: url("https://d2k1ftgv7pobq7.cloudfront.net/meta/c/p/res/images/8215f6659adc202403198fef903a447e/sign-in-with-google.svg");
}

.external-login-container {
  padding: 0 12px;
}

.auth-link-header{
  a{
    font-size: 1.3rem;
  }
}
