@keyframes grow {
    0% {transform: translateY(-160px);}
    30% {transform: translateY(-160px);}
    100% {transform: none;}
}

@keyframes opacityReverse {
    0% {opacity: 1;}
    85% {opacity: 1;}
    100% {opacity: 0;}
}

@keyframes opacity {
    0% {opacity: 0;}
    50% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes cartControlsShadow {
    0% {box-shadow: var(--mui-shadows-4);}
    90% {box-shadow: var(--mui-shadows-4);}
    100% {box-shadow: none;}
}

#modal-or-swipeable-overflow-wrapper{
    scroll-timeline-name: --containerTimeline;
    view-timeline-name: --containerViewTimeline;
}

.product-info-button {
    a.MuiButton-outlined:hover {
        color: var(--mui-palette-primary-main);
    }
}
