.countdown {
  font-size: 4rem;
  line-height: 1;
  display: flex;
  align-content: flex-start;

  &-horizontal {
    flex-direction: row;
    justify-content: center;
  }

  &-vertical {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.countdown-unit {
  display: flex;
  padding-left: 0.5rem;
  width: 100%;

  &-horizontal {
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }

  &-vertical {
    flex-direction: column;
    justify-content: start;
    align-items: center;

    > span.countdown-label {
      font-size: 1rem;
      text-transform: capitalize;
      width: 100%;
      text-align: center;
      padding: 5px;
    }
  }

  &-separator {
    line-height: 0.9;
    padding-left: 0.5rem;
  }
}
