.range-filter-container {
  position: relative;
  box-sizing: border-box;
  color: var(--mui-palette-text-primary, #000);
  height: 100%;

  svg{
    cursor: pointer;
  }

  .range-filter-control {
    align-items: center;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    min-height: 38px;
    outline: 0!important;
    position: relative;
    transition: all 100ms;
    background-color: var(--mui-palette-background-paper, #fff);
    border-color: var(--mui-palette-hint);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    height: 100%;

    &:hover {
      border-color: hsl(0, 0, 70%);
    }

    .range-filter-value-container {
      align-items: center;
      display: grid;
      flex: 1;
      position: relative;
      overflow: hidden;
      padding: 2px 8px;
      box-sizing: border-box;

      .range-filter-value {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .range-filter-indicators-container {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex-shrink: 0;
      box-sizing: border-box;

      .range-filter-indicators-separator {
        align-self: stretch;
        width: 1px;
        background-color: var(--mui-palette-hint);
        margin-bottom: 8px;
        margin-top: 8px;
        box-sizing: border-box;
      }

      .range-filter-indicator-container {
        display: flex;
        transition: color 150ms;
        color: var(--mui-palette-hint);
        padding: 8px;
        box-sizing: border-box;

        &:hover {
          color: hsl(0, 0, 60%);
        }

        .range-filter-indicator {
          display: inline-block;
          fill: currentColor;
          line-height: 1;
          stroke: currentColor;
          stroke-width: 0;
        }
      }
    }
  }

  .range-filter-menu {
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    background-color: var(--mui-palette-background-paper, #fff);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),0 4px 11px hsla(0, 0%, 0%, 0.1);
    margin-bottom: 8px;
    margin-top: 8px;
    box-sizing: border-box;
  }
}



.range-filter-input {
  flex: 1
}

.range-filter-range {
  flex: 2
}

