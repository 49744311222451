.ai-chat-window {
    color: var(--mui-palette-text-primary);

    overflow: hidden!important;

    @media screen and (max-width: 599px) {
        width: 95vw!important;
    }

    @media screen and (min-width: 600px) {
        width: 500px;
    }


    .chat-wrapper {
        .header {
            padding: 10px;

        }
        .chat {
            border: none!important;
        }
    }
}


.ai-chat-toggler {
    &:not(.no-fixed) {
        position: fixed;
        bottom: 15px;
        right: 20px;
        z-index: 1000;
    }

    border-radius: 100%;

    button {
        border-radius: 100%;
    }
}
