


.grid-toggle-container {
  .grid-toggler {
    display: inline-block;

    padding: .1rem .3rem;

    border-radius: 4px;

    &:first-child {
      border-right-width: 0!important;
      border-top-right-radius: 0!important;
      border-bottom-right-radius: 0!important;
    }

    &:last-child {
      border-left-width: 0!important;
      border-top-left-radius: 0!important;
      border-bottom-left-radius: 0!important;
    }

    &.active {
      background-color: var(--mui-palette-primary-main, #000);
      color: var(--mui-palette-background-default, #fff);
      border: 1px solid var(--mui-palette-primary-main, #000);
    }
    &:not(.active) {
      color: var(--mui-palette-primary-main);
      border: 1px solid var(--mui-palette-primary-main);
      cursor: pointer;
    }
  }
}
