.check {
  .check-item {
    .check-item-image-wrapper {
      width: 20%;
      margin-right: .5rem;

      .check-item-image {
        border-radius: 4px;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }
}
