.shop-main-image-wrapper {
    border-radius: 0.5rem;

    @media screen and (min-width: 768px) {
        border-radius: 1rem;
    }

    color: white;
    position: relative;
    // object-fit: cover;
    button{
        color: white !important;
    }
    img{
        @media screen and (max-width: 900px) {
            width: 100%;
            height: auto; //8rem
        }

        border-radius: 0.5rem;
        @media screen and (min-width: 768px) {
            border-radius: 1rem;
        }

        object-fit: cover;
        height: auto; //15rem
    }

    .menu-image-overlay{
        position: absolute;
        height: 5rem;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent);

        border-bottom-left-radius: .5rem;
        border-bottom-right-radius: .5rem;
        @media screen and (min-width: 768px) {
            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
        }
    }

    .bottom-left-text {
        position: absolute;
        bottom: 8px;
        left: 8px;

        @media screen and (min-width: 992px) {
            left: 10px;
        }
        z-index: 2;
    }
}

.shop-info-social {
    width: 45px;
    text-align: center;

    i {
        font-size: 45px!important;
    }
}

.shop-bottom-buttons {
    position: fixed;
    bottom: 4px;
    right: 0;
    max-width: 100vw;
    padding: .25rem!important;

    z-index: 1001;

    @media screen and (min-width: 768px) {
        bottom: 8px;
    }

    @media screen and (min-width: 1400px) {
        max-width: calc(1320px + 6rem);
        right: calc((100vw - 1320px) / 2 - 3rem)
    }

    &.cart-enabled {
        &:not(&:has(.bottom-cart-wrapper.d-none)) {
            left: 0
        }
    }
}

.shop-detailed-product{
    .media {
        width: 100%;
        overflow: hidden;

        img, video {
            width: 100%;
            height: auto;
            max-height: 80vh;
            margin: auto;
            object-fit: contain;
            border-top-left-radius: .5rem;
            border-top-right-radius: .5rem;

            @media screen and (max-width: 991px) {
                max-height: calc((100vh - 36px) * 0.6);
            }
            @media screen and (min-width: 992px) {
                max-height: calc((100vh - 32px) * 0.6);
            }
        }
    }
}

.shop-cart-item{
    .media{
        img{
            height: 4rem;
            width: 4rem;
            object-fit: cover;
            border-radius: 0.5rem;
        }
    }
}

.shop-product-card {
    overflow: hidden;
    .media {
        .shop-product-card-image {
            border-radius: 5px;
        }
    }
}

.order-shop-img{
    height: auto;
    width: 6rem;
    object-fit: cover;
    border-radius: 0.5rem;
}

.shop-order-product-image{
    img{
        height: 4rem;
        width: 4rem;
        object-fit: cover;
    }
}

.shop-categories{
    overflow-y: auto;
}

.product-dialog-modal{
    height: auto;
}

.product-modal-content {
    max-height: calc(100vh - 3.5rem)!important;
}

.product-body-modal-web{
    max-height: 600px;
}

.product-body-modal-tg{
    max-height: 400px;
}

#shop-footer{
    p{
        margin-bottom: 0.5rem;
    }
}

.shop-cart-item-not-in-stock{
    opacity: 0.6;
}

.lang-dropdown{
    top: 1.5rem !important;
}

.lang-list{
    .item:hover, .item.active{
        background-color: var(--mui-palette-primary-main, #000);
        color: var(--mui-palette-primary-contrastText, #fff);
        border-radius: 0 !important;
    }
    .item{
        border-radius: 0 !important;
    }
}



.shop-range-slider {
    .range-slider__range {
        background: var(--mui-palette-primary-light)!important;
    }

    .range-slider__thumb {
        background: var(--mui-palette-primary-main, #000)!important;
    }

    &.shop-range-slider-sm {
        height: .5rem;

        .range-slider__thumb {
            width: 1rem;
            height: 1rem;
        }
    }

}


.shop-chip {
    border-radius: 27px;
    border: 1px solid;
    border-color: var(--mui-palette-hint, lightgrey);
    transition: border-color 500ms;
    cursor: pointer;

    &:not(.disabled).active, &:not(.disabled):hover {
        border-color: var(--mui-palette-primary-main, #000)!important;
    }

    &.disabled {
        color: var(--mui-palette-text-disabled)
    }

    &.not-available.active{
        color: var(--mui-palette-error-main);
    }
}

.shop-select {
    color: var(--mui-palette-text-primary, #000);

    .shop-select__single-value{
        user-select: none!important;
        color: var(--mui-palette-text-primary, #000) !important;
    }

    .shop-select__input-container{
        color: var(--mui-palette-text-primary, #000) !important;
    }

    .shop-select__control {
        background-color: var(--mui-palette-background-default, #fff);
        height: 100%;
        user-select: none;
        cursor: pointer;
    }

    &.minimalistic {
        .shop-select__control {
            color: var(--mui-palette-primary-main);

            border: none!important;
            justify-content: end!important;
            -webkit-justify-content: flex-end!important;

            .shop-select__value-container {
                flex: unset!important;
                padding-right: 0!important;
            }

            .shop-select__indicators {
                .shop-select__indicator-separator {
                    display: none!important;
                }

                .shop-select__indicator:not(.no-rotate) {
                    color: var(--mui-palette-primary-main);
                    transform: rotate(0deg);
                }
            }

            &.shop-select__control--menu-is-open {
                .shop-select__indicators {
                    .shop-select__indicator:not(.no-rotate) {
                        transform: rotate(180deg)!important;
                    }
                }
            }
        }

        .shop-select__menu {
            width: auto;
            min-width: 100%;
            right: 0;
        }
    }

    &.full-width-option {
        max-width: 100%;

        .shop-select__menu {
            .shop-select__option {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    .shop-select__menu {
        z-index: 3;
        background-color: var(--mui-palette-background-paper, #fff)!important;

        .shop-select__menu-list {
            padding-top: 0 !important;
            padding-bottom: 0!important;
        }

        .shop-select__option {
            background-color: var(--mui-palette-background-paper, #fff)!important;
            cursor: pointer;

            &.shop-select__option--is-focused {
                background-color: var(--mui-palette-primary-light)!important;
                color: var(--mui-palette-primary-contrastText, #fff)!important;
            }

            &.shop-select__option--is-selected {
                background-color: var(--mui-palette-primary-main, #000)!important;
                color: var(--mui-palette-primary-contrastText, #fff)!important;
            }
        }
    }

    svg{
        cursor: pointer;
    }
}

.shop-phone-select{
    width: 150px;
}

.shop-phone, .PhoneInput{
    outline: none;
    .PhoneInputInput{
        border: 0;
    }
    &.invalid{
        .PhoneInputInput{
            border-color: #dc4c64;
        }
    }
    &.valid{
        .PhoneInputInput{
            border-color: #14a44d;
        }
    }

    .PhoneInputInput{
        color: var(--mui-palette-text-primary, #000);
        min-height: auto;
        padding: 0.33em 0.75em;
        border-radius: .25rem;
        border: 1px solid #bdbdbd;
        background: transparent;
        transition: all .2s linear;
        outline: none;
    }
}
.menu-carousel{
    width: 100%;
    .carousel-item{
        width: 100%;
        img{
            width: 100%;
            object-fit: cover;
        }
    }
}

.shop-rating{
    i {
        color: var(--mui-palette-primary-main, #000) !important;
    }
}

.cookies-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: transform 0.3s;
    transform: translateY(100%);
    z-index: 9999;
    box-shadow: 0 -1px 10px 0 rgba(87, 87, 87, 0.3);
}

.cookies-banner.show {
    transform: translateY(0);
}


.shop-filters-input {
    flex: 1;
    //box-sizing: border-box;
    //max-width: 100%;
}


.shop-filters-buttons {
    box-sizing: border-box;

    &.expanded {
        width: 100%;
        padding-left: .5rem;
        padding-right: .25rem;
    }

    &:not(.expanded) {
        padding-left: 1rem;
    }

}

.sidebar-border {
    border-width: 3px !important;
}


.flying-img {
    position: fixed;
    z-index: 30000;
    transform: translate(0, 0) scale(1);
    transition: transform 0.5s ease, opacity 0.5s ease;
    transform-origin: center;
    opacity: 1;
}


@keyframes cart-scale {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2); /* Змініть це значення за потребою */
    }
}

.cart-scale-animation {
    animation: cart-scale 0.3s ease;
}


.show-cart-animation {
    transition: transform .3s ease;
    transform: scale(0);
    &.animate {
        transform: scale(1);
        transition: transform .3s ease;
    }
}

.sticky-base {
    position: fixed;
    width: 100%;
    z-index: 999;
}

.sticky-base-sub-categories {
    margin-left: -8px;
}

.shop-hidden-scrollbar::-webkit-scrollbar, .shop-hidden-scrollbar ol::-webkit-scrollbar {
    display: none;
}

.shop-hidden-scrollbar, .shop-hidden-scrollbar ol {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    //noinspection CssInvalidPropertyValue
    overflow: -moz-scrollbars-none;
}
