// for debug overflow
* {
	//outline: 1px solid red !important;
}

a.MuiButton-containedPrimary {
	color: var(--mui-palette-primary-contrastText);
	&:hover,
	&:active,
	&:focus {
		color: var(--mui-palette-primary-contrastText);
	}
}

html:focus-within {
	scroll-behavior: smooth !important;
}

:root {
	--unipos-color: #f5f1e8;
	--success-border: 1px solid var(--mui-palette-success-main, #4caf50);
}

.flex-row {
	display: flex;
	flex-wrap: wrap;
}

html {
	height: 100%;
}

body {
	height: 100%;
	overflow-y: scroll;
	background-color: var(--mui-palette-background-default, #fff);
	font-family: Roboto, Helvetica, Arial, sans-serif;
	color: var(--mui-palette-text-primary, #000) !important;
}

input::selection {
	background-color: var(--mui-palette-primary-main, unset) !important;
	color: var(--mui-palette-primary-contrastText, unset) !important;
}

.scroll-lock {
	position: fixed !important;
	width: 100% !important;
	height: 100% !important;
	overflow-y: scroll !important;

	& > * {
		overflow: hidden !important;
	}
}

#root {
	width: 100%;
	height: 100%;
	min-height: 100%;
}

.text-primary {
	color: var(--mui-palette-primary-main);
}

.text-secondary {
	color: var(--mui-palette-secondary-main);
}

.text-error {
	color: var(--mui-palette-error-main) !important;
}

.text-warning {
	color: var(--mui-palette-warning-main);
}

.text-info {
	color: var(--mui-palette-info-main);
}

.text-success {
	color: var(--mui-palette-success-main);
}

.border-error {
	border-color: var(--mui-palette-error-main) !important;
}

.theme-bg-paper {
	background-color: var(--mui-palette-background-paper, #fff);
	color: var(--mui-palette-text-primary, #000);
}

.theme-bg-default {
	background-color: var(--mui-palette-background-default, #fff);
	color: var(--mui-palette-text-primary, #000);
}

.theme-link,
.theme-link:hover,
.theme-link:active,
.theme-link:focus {
	color: var(--mui-palette-primary-main, #000) !important;
	text-decoration: underline;
}

.no-underline {
	text-decoration: none !important;
}

.tg-danger-text {
	color: var(--mui-palette-error-main) !important;
}

.theme-button,
.theme-button-primary {
	&.outline,
	&.outline:hover,
	&.outline:active {
		background-color: transparent !important;
		color: var(--mui-palette-primary-main, #000) !important;
		border-color: var(--mui-palette-primary-main, #000) !important;
		box-shadow: none !important;
	}

	&:not(.outline) {
		background-color: var(--mui-palette-primary-main, #000) !important;
		color: var(--mui-palette-primary-contrastText, #fff) !important;

		&:not(.shadow-none) {
			box-shadow: 0 4px 9px -4px var(--mui-palette-primary-main, #000) !important;
		}
	}
}

.theme-button-error {
	&.outline {
		background-color: transparent !important;
		color: var(--mui-palette-error-main);
		border-color: var(--mui-palette-error-main) !important;
	}

	&:not(.outline) {
		background-color: var(--mui-palette-error-main) !important;
		color: var(--mui-palette-error-contrastText) !important;

		&:not(.shadow-none) {
			box-shadow: 0 4px 9px -4px var(--mui-palette-error-main) !important;
		}
	}
}

.container-outline {
	box-shadow: none !important;
	border-width: 1px;
	border-style: solid;

	color: var(--mui-palette-primary-main, #000);
	border-color: var(--mui-palette-primary-main, #000);
	padding: 0.5rem 1.375rem 0.375rem 1.375rem;
	border-radius: 0.25rem;
}

.btn-padding {
	padding: 0.5rem 1.375rem 0.375rem 1.375rem;
}

.border-transparent {
	border-color: transparent !important;
}

.unipos-button {
	background-color: var(--unipos-color, #f5f1e8) !important;
	color: #000 !important;
	box-shadow: 0 4px 9px -4px var(--unipos-color, #f5f1e8) !important;

	&:hover,
	&:active,
	&:focus {
		background-color: var(--unipos-color, #f5f1e8) !important;
		box-shadow: 0 4px 9px -4px var(--unipos-color, #f5f1e8) !important;
	}
}

.btn-tertiary {
	color: var(--mui-palette-primary-main, #000) !important;
	background-color: transparent !important;
	box-shadow: none !important;
	&:hover,
	&:active,
	&:focus {
		background-color: transparent !important;
		box-shadow: none !important;
	}
}

.primary-color {
	color: var(--mui-palette-primary-main, #000) !important;
}

.theme-text-primary-contrast {
	color: var(--mui-palette-primary-contrastText, #fff) !important;
}

.theme-link-color {
	color: var(--mui-palette-primary-main, #000);
}

.tg-hint-color {
	color: var(--mui-palette-hint, #8f8f8f);
}

.theme-text {
	color: var(--mui-palette-text-primary, #000);
}

.tg-breadcrumb {
	> .breadcrumb-item::before {
		color: var(--mui-palette-hint, #000) !important;
	}
}

.tg-radio {
	background-color: var(--mui-palette-background-paper, #fff) !important;
	accent-color: var(--mui-palette-primary-main, #000) !important;
	border-color: var(--mui-palette-primary-light) !important;

	&:checked:after {
		background-color: var(--mui-palette-primary-light) !important;
	}

	&:not(:checked):after {
		background-color: var(--mui-palette-background-paper, #fff) !important;
	}
}

.form-check-input[type="checkbox"]:checked::after {
	background-color: var(--mui-palette-primary-contrastText, #fff) !important;
	border-color: var(--mui-palette-primary-main, #000) !important;
}

.tg-checkbox[type="checkbox"]:checked,
.tg-checkbox[type="radio"]:checked::after {
	background-color: var(--mui-palette-primary-contrastText, #fff) !important;
	border-color: var(--mui-palette-primary-main, #000) !important;
}

.tg-checkbox[type="chackbox"]:checked::after {
	background-color: var(--mui-palette-primary-contrastText, #fff) !important;
	border-color: var(--mui-palette-primary-main, #000) !important;
}

.tg-checkbox {
	background-color: var(--mui-palette-primary-contrastText, #fff) !important;
	border-color: var(--mui-palette-primary-main, #000) !important;

	&:focus:after {
		// background-color: var(--mui-palette-primary-contrastText, #fff)!important;
		border-color: var(--mui-palette-primary-contrastText, #000) !important;
	}
}

.tg-modal {
	.modal-dialog {
		background-color: var(--mui-palette-background-default, #fff) !important;

		.modal-header {
			background-color: var(--mui-palette-background-default, #fff) !important;
			color: var(--mui-palette-text-primary, #000) !important;
			border-bottom: 1px solid var(--mui-palette-hint, grey);
		}

		.modal-body {
			background-color: var(--mui-palette-background-default, #fff) !important;
			color: var(--mui-palette-text-primary, #000) !important;

			input {
				background-color: var(--mui-palette-background-paper, #fff) !important;
			}
			.tg-checkbox {
				background-color: var(--mui-palette-primary-contrastText, #fff) !important;
			}
			.tg-checkbox[type="radio"] {
				background-color: var(--mui-palette-primary-main, #000) !important;
			}
		}

		.modal-footer {
			background-color: var(--mui-palette-background-default, #fff) !important;
			color: var(--mui-palette-text-primary, #000) !important;
			border-top: 1px solid var(--mui-palette-hint, grey);
		}
	}
	.modal-dialog.product-dialog-modal {
		background-color: transparent !important;
	}
}

.tg-table-editor-wrapper {
	.tg-table-editor {
		background-color: var(--mui-palette-background-paper, white);
		color: var(--mui-palette-text-primary, red);

		table {
			thead {
				tr {
					border-bottom: 1px solid var(--mui-palette-hint, grey) !important;
				}
			}

			tbody {
				tr:last-child {
					border-bottom: 1px solid var(--mui-palette-hint, grey) !important;
				}
			}

			border-bottom: 1px solid var(--mui-palette-hint, grey) !important;
			color: var(--mui-palette-text-primary, red);

			td {
				color: var(--mui-palette-text-primary, red);
			}

			button {
				color: var(--mui-palette-text-primary, red) !important;
			}
		}
		.select-arrow {
			color: var(--mui-palette-text-primary, red) !important;
		}

		.table-editor__pagination {
			border-top: none;
			margin: 0;
			justify-content: end;

			.table-editor__select-wrapper {
				text-align: center;
				width: 50%;
				padding: 0;

				.table-editor__select-text {
					padding: 0;
					margin: 0 0.5rem 0 0;
				}
			}

			.table-editor__pagination-nav {
				text-align: center;
				margin-left: 0.5rem;
			}

			.table-editor__pagination-buttons {
				text-align: end;
				margin-left: 0.5rem;
			}
		}
	}

	> div:first-child {
		.btn {
			background-color: var(--mui-palette-primary-main, #000) !important;
			color: var(--mui-palette-primary-contrastText, #fff) !important;

			&:disabled {
				background-color: var(--mui-palette-action-disabledBackground) !important;
			}

			&:hover,
			&:active {
				background-color: var(--mui-palette-primary-light) !important;
			}
		}
	}

	.form-label {
		color: var(--mui-palette-text-primary, red) !important;
	}

	.form-control {
		color: var(--mui-palette-text-primary, red) !important;
	}

	.form-control:disabled {
		background-color: var(--mui-palette-background-default, grey);
	}
}

.tg-switch {
	background-color: var(--mui-palette-hint, grey) !important;

	&::after {
		background-color: white !important;
	}

	&:checked {
		background-color: var(--mui-palette-primary-main, #000) !important;
		outline: none;
		&::after {
			background-color: var(--mui-palette-primary-main, #000) !important;
		}
	}
}

.tg-tabs {
	.nav-link:hover {
		background-color: var(--mui-palette-hint, grey) !important;
	}
	.nav-link {
		color: var(--mui-palette-text-primary, #000) !important;
	}
	.nav-link.active {
		color: var(--mui-palette-primary-main, #1266f1) !important;
		border-color: var(--mui-palette-primary-main, #1266f1) !important;
		background-color: var(--mui-palette-background-paper, white) !important;
	}
}

.theme-link,
.theme-link:hover,
.theme-link:focus,
.theme-link:active {
	border: none !important;
	background: none !important;
	box-shadow: none !important;
	color: var(--mui-palette-primary-main, black) !important;
}

.rfs-1 {
	font-size: 1vw !important;
}

.rfs-2 {
	font-size: 2vw !important;
}

.rfs-3 {
	font-size: 3vw !important;
}

.rfs-3-5 {
	font-size: 3.5vw !important;
}

.rfs-4 {
	font-size: 4vw !important;
}

.rfs-5 {
	font-size: 5vw !important;
}

.dropdown-menu a.dropdown-item {
	background-color: var(--mui-palette-background-paper, #fff) !important;
	color: var(--mui-palette-text-primary, #000) !important;
}

.dropdown-menu a.dropdown-item:hover {
	background-color: var(--mui-palette-primary-main, #000) !important;
	color: var(--mui-palette-primary-contrastText, #fff) !important;
}

.popconfirm {
	background-color: var(--mui-palette-background-paper, #fff) !important;
	color: var(--mui-palette-text-primary, #000) !important;

	.popconfirm-message {
		background-color: var(--mui-palette-background-paper, #fff) !important;
		color: var(--mui-palette-text-primary, #000) !important;
	}
}

.popconfirm-backdrop {
	z-index: 99999;
}

.my-countdown {
	.countdown-unit {
		width: auto !important;
	}
}

.form-switch .form-check-input:before {
	display: none;
}

.form-check-input[type="checkbox"]:checked:focus:before {
	box-shadow: 0 0 0 13px var(--mui-palette-primary-main);
}

.input-no-outline,
.input-no-outline:focus,
.input-no-outline:active {
	outline: none !important;
	border-width: 0 !important;
}

.input-bottom-border-container {
	border-top-width: 0;
	border-bottom-width: 0;
	border-left-width: 0;
	border-right-width: 0;

	border-style: solid;
	border-color: var(--mui-palette-hint);
	color: var(--mui-palette-text-primary);

	// transition: all .5s;
	padding: 0.2rem 0.3rem 0 0.4rem;

	display: flex;

	.input-bottom-border-search-icon {
		display: inline-block;
		margin-right: 0.5rem;
		transition: all 0.5s;
	}

	input {
		outline: none !important;
		border: none !important;
		caret-color: var(--mui-palette-primary-main);
		background-color: var(--mui-palette-background-default);
		color: var(--mui-palette-text-primary);

		flex: 1;

		&::placeholder {
			color: var(--mui-palette-text-secondary);
		}
	}

	&:has(input:focus),
	&:has(input:active) {
		border-color: var(--mui-palette-primary-main);
		border-bottom-width: 0px; //Search input border

		.input-bottom-border-search-icon {
			color: var(--mui-palette-primary-main);
		}
	}
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-default {
	cursor: default;
}

.cursor-not-allowed {
	cursor: not-allowed;
}

.pre-wrap {
	white-space: pre-wrap;
}

.drop-mdb-btn-style {
	button {
		all: unset;
		cursor: pointer;
	}
	button:hover {
		background-color: unset;
		cursor: pointer;
	}
	.ripple-wave {
		display: none !important;
	}
}

.mdb-tabs-horizontal-scroll {
	.nav-tabs {
		overflow-x: auto;
		overflow-y: hidden;
		flex-wrap: nowrap;

		.nav-link {
			white-space: nowrap;
		}
	}
}

.tg-select {
	.select-arrow {
		color: var(--mui-palette-text-primary, black) !important;
	}
	input {
		color: var(--mui-palette-text-primary, black) !important;
	}
	.form-control {
		&:disabled {
			background-color: var(--mui-palette-action-disabledBackground, #ebebeb);
		}
	}
}

.tg-chip {
	background-color: var(--mui-palette-primary-main, white) !important;
	color: var(--mui-palette-primary-contrastText, black) !important;

	.close {
		color: var(--mui-palette-primary-contrastText, black) !important;
	}
}

.translation-text-wrapper {
	max-height: 20rem;
	overflow-y: auto;
}

.tg-card {
	background-color: var(--mui-palette-background-paper, white) !important;
	color: var(--mui-palette-text-primary, black) !important;
}

.tg-input,
.form-label {
	color: var(--mui-palette-text-primary, black) !important;
}

.tg-group {
	//.form-control{
	//    opacity: 0.7;
	//}
	.form-control:focus {
		background-color: var(--mui-palette-background-paper, white) !important;
		color: var(--mui-palette-text-primary, black) !important;
		//opacity: 0.7;
	}
	.form-control::placeholder {
		color: var(--mui-palette-text-primary, black) !important;
		opacity: 0.7;
	}
	.form-control,
	button {
		color: var(--mui-palette-text-primary, black) !important;
	}
}

.tg-input-not-required {
	opacity: 0.6;
}

.small-text-7 {
	font-size: 0.7rem !important;
}

.text-white {
	color: white !important;
}

.dropdown-menu {
	z-index: 1000000 !important;
}

.border-radius-05 {
	border-radius: 0.5rem !important;
}

.red-cross-out {
	position: relative;
	text-decoration: none;
}

.red-cross-out::before {
	content: "";
	position: absolute;
	bottom: 50%;
	right: 0;
	width: 100%;
	height: 2px;
	background-color: red;
	transform: rotate(0deg);
	transform-origin: 0 0;
}

.tg-list-group-item.active {
	background-color: var(--mui-palette-primary-main) !important;
	color: var(--mui-palette-primary-contrastText, #fff) !important;
}

.select-dropdown-container {
	z-index: 10000 !important;
}

.mui-outlined-button-primary {
	box-shadow: none !important;
	background-color: transparent !important;
	color: var(--mui-palette-primary-main) !important;
	border: 1px solid rgba(var(--mui-palette-primary-mainChannel) / 0.5) !important;
	border-radius: var(--mui-shape-borderRadius) !important;
	padding: 5px 15px;

	&:hover {
		border: 1px solid var(--mui-palette-primary-main) !important;
	}
}

//override bs base colors start
.alert-warning {
	background-color: var(--mui-palette-warning-main) !important;
	color: var(--mui-palette-warning-contrastText) !important;

	i {
		color: var(--mui-palette-warning-contrastText) !important;
	}
}

.alert-danger {
	background-color: var(--mui-palette-error-main) !important;
	color: var(--mui-palette-error-contrastText) !important;
}

.theme-bg-error {
	background-color: var(--mui-palette-error-main) !important;
	color: var(--mui-palette-error-contrastText) !important;
}

.theme-bg-primary {
	background-color: var(--mui-palette-primary-main) !important;
	color: var(--mui-palette-primary-contrastText) !important;
}

.text-danger {
	color: var(--mui-palette-error-main) !important;
}
.warning-link,
.warning-link:hover,
.warning-link:active,
.warning-link:focus {
	color: var(--mui-palette-warning-dark) !important;
}
//override bs base colors end

.popover-header {
	border-radius: 0.5rem 0.5rem 0 0 !important;
}
.popover-body {
	border-radius: 0 0 0.5rem 0.5rem !important;
}

.stepper-mobile-progress-bar {
	background-color: var(--mui-palette-primary-main, #000) !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
	background-color: var(--mui-palette-primary-main, #000) !important;
	color: var(--mui-palette-primary-contrastText, #fff) !important;
	height: 2.7rem !important;
	width: 2.7rem !important;
	border-radius: 50%;
}

.carousel-indicators {
	z-index: 3;
	top: 0;
	bottom: unset;
	background-color: var(--mui-palette-primary-main, #000) !important;
	color: var(--mui-palette-primary-contrastText, #fff) !important;
	border-radius: 0 0 0.5rem 0.5rem;
	opacity: 0.6;
}

.one-line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@supports (-webkit-line-clamp: 2) {
	.two-lines {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

@supports (-webkit-line-clamp: 1) {
	.one-line-w {
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.flex-1 {
	flex: 1;
}

.object-fit-cover {
	object-fit: cover;
}

.btn-round-left-0 {
	border-radius: 0 0.25rem 0.25rem 0 !important;
}

.no-shadow {
	box-shadow: none !important;
	&:hover {
		box-shadow: none !important;
	}
}

.input-span-border {
	border-style: solid;
	border-color: #bdbdbd;
	border-top-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 0;
	border-right-width: 0;
}

.scroll-smooth {
	scroll-behavior: smooth;
}

.ReactCollapse--collapse {
	transition: height 500ms;
	overflow: hidden !important;
}

.blurred-element {
	filter: url(#blur-filter);
	background-color: var(--mui-palette-background-paper, #fff);
	opacity: 0.5;
}

.full-page {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10000;
}

.mh-100vh {
	max-height: 100vh;
}

.hidden-input {
	.form-notch {
		display: none !important;
	}
}

.justify-self-start {
	justify-self: start;
}

.justify-self-end {
	justify-self: end;
}

.white-space-nowrap {
	white-space: nowrap;
}

.left-right-lines {
	display: flex;
	width: 100%;
	position: relative;

	&:before,
	&:after {
		flex: 1;
		display: inline-block;
		align-self: center;
		height: 1px;
		content: "";
		background-color: var(--mui-palette-hint, #e6e6e6);
	}
	&:before {
		margin-right: 15px;
	}
	&:after {
		margin-left: 15px;
	}
}

.text-transform-none {
	text-transform: none;
}

.border-grey {
	border-color: var(--mui-palette-hint) !important;
}

.fw-500 {
	font-weight: 500;
}

.mw-50 {
	max-width: 50% !important;
}

.mw-40 {
	max-width: 40% !important;
}

.mw-30 {
	max-width: 30% !important;
}

.fs-7 {
	font-size: 0.9rem !important;
}

.w-fit-content {
	width: fit-content;
}

.overflow-x-hidden {
	overflow-x: hidden !important;
}

.min-h-25 {
	min-height: 25%;
}

.min-h-50 {
	min-height: 50%;
}

.min-h-100 {
	min-height: 100%;
}

.max-h-100 {
	max-height: 100%;
}

.mw-auto {
	min-width: auto !important;
}

.table {
	color: var(--mui-palette-text-primary) !important;
}

.form-control {
	color: var(--mui-palette-text-primary) !important;
}

.input-padding-bottom-0 {
	input {
		padding-bottom: 0;
	}
}

.active .react-list-link {
	color: var(--mui-palette-primary-contrastText, #fff) !important;
	&:hover {
		color: var(--mui-palette-primary-contrastText, #fff) !important;
	}
}

.react-list-link {
	&:hover {
		color: var(--mui-palette-text-primary, #000) !important;
	}
}

.shop-product-card {
	.shop-product-card-image {
		width: 100%;
		object-fit: contain;
		user-select: none;
	}
}

.rounded-icon-success-wrapper {
	border-radius: 50%;
	border: var(--success-border);
	width: 52px;
	height: 52px;
	position: absolute;
	top: -1.5rem;
	background: var(--mui-palette-background-paper, #fff);
	left: 50%;
	transform: translate(-50%);
}

.white-space-pre {
	white-space: pre;
}

.is-sticky {
	position: fixed;
	top: calc(88px); //header height
	z-index: 999;
	animation: 500ms ease-in-out 5s normal none 1 running fadeInDown;
}

.dropdown-active-item {
	a {
		background-color: var(--mui-palette-primary-main, #000) !important;
		color: var(--mui-palette-primary-contrastText, #fff) !important;
	}
}

.tooltip.show,
.popover.fade.show {
	z-index: 9999;
}

.word-break-all {
	word-break: break-all;
}

.no-top-shadow {
	clip-path: inset(0px -10px -10px -10px);
}

a {
	color: var(--mui-palette-primary-main);
	text-decoration: underline;
	text-decoration-color: rgba(var(--mui-palette-primary-mainChannel) / 0.4);

	&:not(.MuiButton-root):hover {
		color: var(--mui-palette-primary-main);
		text-decoration: underline;
		text-decoration-color: inherit;
	}

	&.MuiButton-root:hover {
		color: var(--mui-palette-primary-contrastText);
	}
}

hr {
	margin: unset;
	color: unset;
	border: unset;
	border-top: unset;
	opacity: unset;
}

p {
	margin-bottom: 0;
}

// Swiper

.swiper-pagination-bullet {
	background-color: var(--mui-palette-text-primary) !important;
	border-radius: 50%;
	width: 10px !important;
	height: 10px !important;
}

.MuiSvgIcon-fontSizeXl2 {
	font-size: 1.5rem !important;
}
