.loader-container {
	&.show {
		opacity: 1;
		display: block;
		transition: opacity, display .5s;
	}

	&:not(.show) {
		opacity: 0;
		transition: opacity, display .5s;
		display: none;
	}
}


