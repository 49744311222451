*, *:before, *:after {
    box-sizing: border-box;
}

.chat-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-height: inherit;
    min-height: inherit;
    color: var(--mui-palette-text-primary, #000);
    background-color: var(--mui-palette-background-paper, #fff);
    display: flex;
    flex-direction: column;

    .chat {
        max-height: inherit;
        position: relative;
        overflow: hidden;
        border-width: 1px;
        border-style: solid;
        border-color: var(--mui-palette-hint, #e6e6e6);
        height: 100%;

        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        flex: 1;

        .header {
            text-align: center;
        }

        .message-input {
            width: 100%;
            padding: .8rem;

            box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
            z-index: 1 ;
            background-color: var(--mui-palette-background-default, #eceff1);
            display: flex;
            align-items: center;

            input {
                width: calc(100% - 20px);
                color: var(--mui-palette-text-primary, #000);
                border: 0;
                outline: none;
                background-color: var(--mui-palette-background-default, #eceff1);
            }

            .message-input-link {
                margin-left: .25rem;
                color: var(--mui-palette-primary-main, #000);
            }
        }

        .error {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            user-select: none;
            color: var(--mui-palette-error-main);
        }

        .no-messages {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            user-select: none;
            color: var(--mui-palette-text-primary);
            opacity: 50%;
        }

        .messages-container {
            max-height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            padding: 1rem 1.2rem;

            &:has(.no-messages), &:has(.error) {
                align-items: center!important;
                justify-content: center!important;
            }

            .date {
                position: relative;
                width: 100%;
                margin-top: 27px;
                margin-bottom: 27px;
                text-align: center;
                span {
                    font-size: 14px;
                    display: inline-block;
                    color: var(--mui-palette-hint, #999);
                    user-select: none;
                    z-index: 2;

                    &:before, &:after {
                        z-index: 1;
                        position: absolute;
                        top: 50%;
                        transform: translateY(50%);
                        display: inline-block;
                        width: 20%;
                        height: 1px;
                        content: '';
                        background-color: var(--mui-palette-hint, #e6e6e6);
                    }
                    &:before {
                        left: 0;
                    }
                    &:after {
                        right: 0;
                    }
                }
            }

            .message {
                font-size: 16px;
                position: relative;
                display: inline-block;
                clear: both;
                margin-bottom: 8px;
                padding: .8rem;
                vertical-align: top;
                border-radius: 5px;
                white-space: pre-line;
                max-width: 100%;

                &:before {
                    position: absolute;
                    bottom: .2px;
                    display: block;
                    width: 0;
                    height: 0;
                    border-top: 8px solid transparent;
                    border-bottom: 8px solid transparent;

                    content: '\00a0';
                }

                &:has(.products) {
                    .message-text {
                        margin-top: 10px!important;
                    }
                }

                &.assistant {
                    float: left;

                    .message-text {
                        color: var(--mui-palette-secondary-contrastText, #000);
                    }

                    background-color: var(--mui-palette-secondary-main, #eceff1);
                    align-self: flex-start;
                    animation-name: slideFromLeft;
                    &:before {
                        border-right: 12px solid var(--mui-palette-secondary-main, #eceff1);;
                        border-bottom-right-radius: 3px;
                        left: 4px;
                        transform: translateX(-100%);
                    }
                }

                &.user {
                    float: right;

                    .message-text {
                        color: var(--mui-palette-primary-contrastText, #fff);
                    }

                    background-color: var(--mui-palette-primary-main, #000);
                    align-self: flex-end;
                    animation-name: slideFromRight;

                    &:before {
                        border-left: 12px solid var(--mui-palette-primary-main, #000);
                        border-bottom-left-radius: 3px;
                        right: 4px;
                        transform: translateX(100%);
                    }
                }

                &.system {
                    width: 100%!important;
                    background-color: var(--mui-palette-info-main);

                    .message-text {
                        color: var(--mui-palette-info-contrastText);
                    }
                }

                &.error {
                    width: 100%!important;
                    background-color: var(--mui-palette-error-main);

                    .message-text {
                        color: var(--mui-palette-error-contrastText);
                    }
                }


                .products {
                    padding: .5rem;
                    max-width: 100%;
                    overflow-x: scroll!important;
                    background: var(--mui-palette-background-paper);
                    border-radius: 5px;

                    display: flex;
                    align-items: stretch;
                    gap: .5rem;

                    .product {
                        margin-left: auto!important;
                        margin-right: auto!important;
                        display: flex;
                        flex-direction: column;
                        .shop-product-card {
                            flex: 1;
                            width: 250px!important;
                        }
                    }
                }
            }
        }
    }

}



@keyframes slideFromLeft {
    0% {
        margin-left: -200px;
        opacity: 0;
    }
    100% {
        margin-left: 0;
        opacity: 1;
    }
}
@-webkit-keyframes slideFromLeft {
    0% {
        margin-left: -200px;
        opacity: 0;
    }
    100% {
        margin-left: 0;
        opacity: 1;
    }
}

@keyframes slideFromRight {
    0% {
        margin-right: -200px;
        opacity: 0;
    }
    100% {
        margin-right: 0;
        opacity: 1;
    }
}
@-webkit-keyframes slideFromRight {
    0% {
        margin-right: -200px;
        opacity: 0;
    }
    100% {
        margin-right: 0;
        opacity: 1;
    }
}
